export interface ILoanProcessState {
  step: ELoanProcessStep;
  gstIn: string | null;
  gstUsername: string | null;
  otp: number | null;
  otpValidated: boolean;
  loading: boolean;
  error: string | null;
}

export interface IGSTCredentials {
  gstIn: string;
  gstUsername: string;
}

export interface IValidateOtpRequest{
  otp: number
  partner?: string;
}

export interface IGenerateOtpRequest{
  gstIn: string;
  gstUsername: string;
  partner?: string;
}

export interface IValidateOtpResponse{
  otp?: number;
  validated?: boolean;
  message?: string;
  error?: string;
}

export interface IGenerateOtpResponse{
  otpGenerated?: boolean;
  message?: string;
  error?: string;
}

export interface ILoanProcessResponse<T>{
  success: boolean;
  result: T;
  errorCode: string;
}

export enum ELoanProcessStep {
  PROCESS_BEGIN,
  GENERATE_GST_OTP,
  GENERATE_GST_OTP_FAILED,
  VALIDATE_GST_OTP,
  VALIDATE_GST_OTP_FAILED,
  PROCESS_COMPLETE,
}

export interface ILoanProcessStep{
  type: ELoanProcessStep[];
  name: string;
  index: number;
  route: string;
}
