import { Col, Row } from "antd";
import ProgressContainer from "../../components/ProgressContainer";
import './index.scss'

const Success = () => {
  return (
    <div id='success-page'>
      <ProgressContainer>
        <Row>
          <Col>
            <h1> Your OTP has been successfully validated</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="text">
              We are getting the data from GSTN. We will be in touch with you shortly. Meanwhile, if you need anything please WhatsApp us on
            </span>
            <span className="text--highlight"> +91 9663458899</span>
          </Col>
        </Row>
      </ProgressContainer>
    </div>
  );
};

export default Success;