import axios from "axios"
import { IGenerateOtpRequest, IGenerateOtpResponse, ILoanProcessResponse, IValidateOtpRequest, IValidateOtpResponse } from "../../redux/reducers/typings"

const apiUrl = 'https://qa-backend.jitfin.co/api/services';
const apiVer = 'v1';
export interface ILoanProcessServices{
  generateOtp: (payload: IGenerateOtpRequest) => Promise<ILoanProcessResponse<IGenerateOtpResponse>>;
  validateOtp: (payload: IValidateOtpRequest) => Promise<ILoanProcessResponse<IValidateOtpResponse>>;
}
const loanProcessServices = () => {

  const token ='aml0ZmluX3BpcGVzX3FhX3VzZXJuYW1lOmppdGZpbl9waXBlc19xYV9wYXNz';

  const generateOtp = (payload: IGenerateOtpRequest): Promise<ILoanProcessResponse<IGenerateOtpResponse>> => {
    const {gstIn: gstin, gstUsername: gst_username, partner} = payload;
    const url = `${apiUrl}/${apiVer}/generate-wep-otp`;
    // return new Promise((resolve,reject) => (resolve({
    //   success: true,
    //   result: {
    //     otpGenerated: true,
    //     message: 'Otp generated',
    //     error: ''
    //   } as IGenerateOtpResponse,
    //   errorCode: ''
    // })));
    return axios.post(url, {gstin, gst_username, partner}, { headers: {
      'Authorization': `Basic ${token}` 
    }}); 
  }

  const validateOtp = (payload: IValidateOtpRequest): Promise<ILoanProcessResponse<IValidateOtpResponse>> => {
    const url = `${apiUrl}/${apiVer}/validate-wep-otp`;
    // return new Promise((resolve, reject) => (resolve({
    //   success: true,
    //   result: {
    //     otp: 2931,
    //     validated: true
    //   } as IValidateOtpResponse,
    //   errorCode: ''
    // })));
    return axios.post(url, payload, { headers: {
      'Authorization': `Basic ${token}` 
    }});
  }
  return {generateOtp, validateOtp} as ILoanProcessServices;
}
const LoanProcessServices = loanProcessServices();
export default LoanProcessServices;