import { ILoanProcessState } from './../redux/reducers/typings';
import { useDispatch, useSelector } from 'react-redux';
import {useMemo} from 'react';
import { AnyAction, Dispatch } from 'redux';
import { IGSTCredentials, IValidateOtpRequest } from '../redux/reducers/typings';
import loanProcessActions from '../redux/actions/loanProcessActions';
import { AppState } from '../redux/reducers';

interface ISelectors{
  loanProcessState: ILoanProcessState;
}

interface IActionDispatchers{
  beginLoanProcess: () => void;
  fetchGstOtp: (payload: IGSTCredentials) => void;
  validateGstOtp: (payload: IValidateOtpRequest) => void;
}
const useLoanProcess = (): ISelectors & IActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector)
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);
  return {...selectors, ...actionDispatchers};
}

const stateSelector = (state: AppState): ISelectors => ({
  loanProcessState: state.loanProcess
})

const actions = (dispatch: Dispatch<AnyAction>): IActionDispatchers => ({
  beginLoanProcess: () => {
    dispatch(loanProcessActions.beginLoanProcessActions.started());
  },
  fetchGstOtp: (payload: IGSTCredentials) => {
    dispatch(loanProcessActions.generateGstOtpActions.started(payload))
  },
  validateGstOtp: (payload: IValidateOtpRequest) => {
    dispatch(loanProcessActions.validateGstOtpActions.started(payload));
  }
})

export default useLoanProcess;