import React from 'react';
import { Col, Layout, Row } from 'antd';
import SiteRoutes from '../routing/SiteRoutes';
import SiteHeader from './SiteHeader';
import './sitelayout.scss'

const { Header, Footer, Content } = Layout;
export const SiteLayout = () => {
  return (
    <div id="loan-portal">
      <Layout>
        <Row justify={'center'}>
          <Col xs={24} sm={20}>
            <Header>
              <SiteHeader />
            </Header>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col xs={24} md={20} lg={16}>
            <Content>
              <SiteRoutes />
            </Content>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Footer></Footer>
          </Col>
        </Row>
      </Layout>
    </div>
  )
}