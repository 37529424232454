import { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import OtpInput from 'react-otp-input';
import { ELoanProcessStep, IGSTCredentials } from "../../redux/reducers/typings";
import Timer from "../../components/Timer";
import ProgressContainer from "../../components/ProgressContainer";
import useLoanProcess from "../../hooks";
// import { useHistory } from "react-router";
import './index.scss';

interface IGSTRequestForm extends IGSTCredentials {
  toc: boolean;
}

const GstOtpForm = () => {
  const [otp, setOtp] = useState<string>('');
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const [timerEnabled, settimerEnabled] = useState(true);
  const {
    loanProcessState: { error, loading, gstIn, gstUsername },
    validateGstOtp,
    fetchGstOtp
  } = useLoanProcess();
  const otpErrored = !!error;
  const onSubmit = (values: any): void => {
    validateGstOtp(values)
  };

  const resendOtpAndDisableBtn = (): void => {
    fetchGstOtp({ gstIn, gstUsername } as IGSTCredentials);
    setIsResendDisabled(true);
    settimerEnabled(true);
  }

  const onTimerStop = () => {
    setIsResendDisabled(false);
    settimerEnabled(false);
  }

  return (
    <div className="form__wrapper">
      <Row>
        <Col span={24}>
          <div className="form__title">
            Enter the OTP sent on your mobile number
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="flex-y otp-wrapper form-gst-otp form">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              className="otp-input mt--10"
              hasErrored={otpErrored}
              errorStyle="input--error"
            />
          </div>
        </Col>
      </Row>
      <Row justify="start" align="middle" style={{ padding: '10px 10px 10px 0' }}>
        <Col span={24}>
          <Button
            type="link"
            disabled={isResendDisabled}
            size="small"
            onClick={resendOtpAndDisableBtn}
            className='button button--textual button--left'
          >
            {timerEnabled && (<><span className='text'>Resend OTP in </span><Timer onStop={onTimerStop} resetInterval={30} enabled={timerEnabled} /></>)}
            {!timerEnabled && (<><span className='text'>Resend OTP</span></>)}
          </Button>
        </Col>
      </Row>
      <Row
        className='row__otp-actions'
        justify="center"
        align="middle"
      >
        <Col span={24}>
          <Button
            disabled={!(otp && otp.length === 6)}
            onClick={onSubmit}
            loading={loading}
            className='button button--primary button--full'
          >
            Validate OTP
          </Button>
        </Col>
      </Row>
    </div>
  )
}

const GstForm = () => {
  const { fetchGstOtp, loanProcessState: { loading, error } } = useLoanProcess();
  const onSubmit = (values: IGSTRequestForm) => {
    fetchGstOtp(values);
  };
  const gstHelpClicked = () => { };
  const [form] = Form.useForm();
  const initialValues: IGSTRequestForm = { toc: false } as IGSTRequestForm;

  return (
    <div className="form__wrapper">
      <Row>
        <Col>
          <div className="form__title">
            Submit your details, our team will get in touch with you soon
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="gst-form"
            layout='vertical'
            initialValues={initialValues}
            onFinish={onSubmit}
            autoComplete="off"
            className='form-gst-creds form form--full'
          >
            <Form.Item
              label="Enter your GST number">
              {(error as any)?.gstin && <div className="error-display">{(error as any)?.gstin}</div>}
              <Form.Item
                name="gstIn"
                rules={[{ required: true, message: 'Enter valid GST number' }]}
              >
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Enter your GST username">
              {(error as any)?.gstin && <div className="error-display">{(error as any)?.gst_username}</div>}
              <Form.Item
                name="gstUsername"
                rules={[{ required: true, message: 'Enter valid GST username' }]}
              >
                <Input />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name='toc'
              rules={[{ required: true, message: 'You have to agree to the terms and condition' }]}
              valuePropName='checked'
            >
              <Row justify='space-between' align='middle' className='row__gst-actions'>
                <Col xs={24} sm={18}>
                  <Checkbox name="toc">
                    I agree to the terms and conditions
                  </Checkbox>
                </Col>
                <Col sm={6} className='col__help'>
                  <Button
                    type="link"
                    size="small"
                    onClick={gstHelpClicked}
                    className='button button--textual button--right'
                  >
                    Need Help?
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => <Button
                type="primary"
                loading={loading}
                htmlType='submit'
                disabled={!Object.values(form.getFieldsValue() as IGSTRequestForm).every(val => !!val)}
                className='button button--primary button--full'
              >
                Generate OTP
              </Button>}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

const GSTProcess: React.FC = () => {
  const { loanProcessState: { step } } = useLoanProcess();
  // const history = useHistory();
  // console.log(history);
  const isGSTForm = (step === ELoanProcessStep.GENERATE_GST_OTP || step === ELoanProcessStep.GENERATE_GST_OTP_FAILED)

  return (
    <div id="gst-page">
      <ProgressContainer>
        {isGSTForm ? <GstForm /> : <GstOtpForm />}
      </ProgressContainer>
    </div>
  );
};

export default GSTProcess;