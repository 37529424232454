import { useEffect, useState } from "react";

interface ITimerProps {
  resetInterval?: number;
  onStop: Function;
  enabled: boolean;
};

const RESET_INTERVAL_S:number = 180;

const formatTime = (time: number) => 
  `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`;
  

const Timer = ({ onStop, resetInterval = RESET_INTERVAL_S, enabled }: ITimerProps) => {
  const [time, setTime] = useState(resetInterval);
  const [timerRunning, settimerRunning] = useState(true);

  useEffect(() => {
    if(timerRunning){
      const timerId = setInterval(() => {
        setTime((t) => t - 1);
      }, 1000);
    return () => clearInterval(timerId);
    // } else {
    //   enabled && settimerRunning(enabled);
    //   enabled && setTime(resetInterval);
    }
  }, [timerRunning, enabled, resetInterval]);
  // const timeRemain = resetInterval - time;

  if(timerRunning && time === 0){
    onStop();
    settimerRunning(false);
    return <></>
  }
  return (
    <span className="countdown-timer">{formatTime(time)}</span>
  );
};

export default Timer;


