import actionCreatorFactory from "typescript-fsa";
import { IGenerateOtpResponse, IGSTCredentials, IValidateOtpRequest, IValidateOtpResponse } from "../reducers/typings";

const actionCreator = actionCreatorFactory();

const beginLoanProcessActions = actionCreator.async<void, void, void>("BEGIN_LOAN_PROCESS");

const generateGstOtpActions = actionCreator.async<IGSTCredentials, IGenerateOtpResponse, Error>('GENERATE_GST_ACTIONS');

const validateGstOtpActions = actionCreator.async<IValidateOtpRequest, IValidateOtpResponse, Error>('VALIDATE_GST_OTP');

const timerActions = actionCreator.async<IValidateOtpRequest, IValidateOtpResponse, Error>('TIMER_ACTIONS');

const loanProcessActions = {
  beginLoanProcessActions,
  generateGstOtpActions,
  validateGstOtpActions
};

export default loanProcessActions;