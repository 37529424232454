import { Row, Col } from 'antd';
import ProgressStepper from "../ProgressStepper";
import './index.scss'

type ContainerProps = {
  children: React.ReactNode,
  className?: string;
  disableProgressBar?: boolean;
}
const ProgressContainer = ({children, className = '', disableProgressBar = false}:ContainerProps) => {
  return (
    <div className={`${className} page__wrapper`}>
      {
      !disableProgressBar &&
      <Row justify='center'>
        <Col xs={24}>
          <div className="app__progress-stepper">
            <ProgressStepper />
          </div>
        </Col>
      </Row>
      }
      <Row justify="center">
        <Col xs={24} sm={20} md={16}>
          <div className="page__container">
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ProgressContainer;