import { useHistory } from "react-router";
import { Button, Col, Row } from "antd";
import ProgressStepper from "../../components/ProgressStepper";
import ProgressContainer from "../../components/ProgressContainer";
import './index.scss';
import useLoanProcess from "../../hooks";

const Home: React.FC = () => {
  const history = useHistory();
  const { beginLoanProcess } = useLoanProcess();

  const beginProcess = () => {
    beginLoanProcess();
    history.push('/enable-gst-otp')
  };

  return (
    <div id="home-page">
      <Row justify='center'>
        <Col xs={24}>
          <h2 className="page__heading heading--main">
            Thank you for your interest in a loan with JitFin!
          </h2>
          <div className='text page__intro'>
            Follow this simple process of sharing your GST with OTP.
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24}>
          <div className="app__progress-stepper">
            <ProgressStepper />
          </div>
        </Col>
      </Row>
      <Row justify='center'>
        <Col>
          <ProgressContainer disableProgressBar>
          <div className="page__content">
            <div className='page__title'>Instructions</div>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <p className="text page__cta-helper">
              Click on proceed when done
            </p>
          </div>
          </ProgressContainer>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} sm={20} md={16}>
          <Row justify='space-between' className='page__cta'>
            <Col xs={8} md={6}>
              <Button
                type="link"
                size="small"
                className='button button--textual button--left'
              >
                Need Help?
              </Button>
            </Col>
            <Col xs={8} md={6} className='clear'>
              <Button
                onClick={beginProcess}
                className='button button--primary float--right'
              >
                Proceed
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;