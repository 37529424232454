import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';
import StateRouteManager from '../components/RouteManagement';
import GSTProcess from '../pages/GSTProcess';
import Home from '../pages/Home';
import Success from '../pages/Success';

const SiteRoutes = () => {
  const gstPageLinks = [
    '/enable-gst-otp',
    '/validate-gst-otp',
  ]
  return (
      <BrowserRouter>
        <StateRouteManager/>
        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>
          <Route path={gstPageLinks} exact>
            <GSTProcess />
          </Route>
          <Route path='/success' exact>
            <Success />
          </Route>
        </Switch>
      </BrowserRouter>
  )
};

export default SiteRoutes;