import { reducerWithInitialState } from "typescript-fsa-reducers";
import loanProcessActions from "../actions/loanProcessActions";
import { ELoanProcessStep, ILoanProcessState } from "./typings";

const initialState: ILoanProcessState = {
  step: ELoanProcessStep.PROCESS_BEGIN,
  gstIn: null,
  gstUsername: null,
  otp: null,
  otpValidated: false,
  loading: false,
  error: null,
};

const loanProcessReducer = reducerWithInitialState(initialState)
.case(loanProcessActions.beginLoanProcessActions.started, (state) => ({
  ...state,
  step: ELoanProcessStep.GENERATE_GST_OTP,
}))
.case(loanProcessActions.beginLoanProcessActions.done, (state) => ({
  ...state,
  step: ELoanProcessStep.GENERATE_GST_OTP,
}))
  .case(loanProcessActions.generateGstOtpActions.started, (state, payload) => ({
    ...state,
    loading: true,
    gstIn: payload.gstIn,
    gstUsername: payload.gstUsername,
  }))
  .case(loanProcessActions.generateGstOtpActions.done, (state, payload) => ({
    ...state,
    loading: false,
    step: payload.result.otpGenerated ? ELoanProcessStep.VALIDATE_GST_OTP : ELoanProcessStep.GENERATE_GST_OTP_FAILED,
    error: /* !payload.result?.otpGenerated ? payload.result.error : */ null,
  }))
  .case(loanProcessActions.generateGstOtpActions.failed, (state, payload) => ({
    ...state,
    loading: false,
    error: payload as any,
  }))
  .case(loanProcessActions.validateGstOtpActions.started, (state, payload) => ({
    ...state,
    loading: true,
    otp: payload.otp,
    error: null
  }))
  .case(loanProcessActions.validateGstOtpActions.failed, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error as any,
  }))
  .case(loanProcessActions.validateGstOtpActions.done, (state, payload) => ({
    ...state,
    step: payload.result.validated ? ELoanProcessStep.PROCESS_COMPLETE : ELoanProcessStep.VALIDATE_GST_OTP_FAILED,
    loading: false,
    otpValidated: true,
    error: /*!payload.result.validated ? payload.result.error : */ null,
  }));

export default loanProcessReducer;
