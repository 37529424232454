import { Col, Row } from "antd";
import { ReactComponent as Logo } from '../assets/images/logo.svg';

const SiteHeader: React.FC = () => {
  return (
    <div className="header">
      <Row>
        <Col className='col--logo'>
          <h1>
            <a href='/' title="JitFin">
              <Logo className='logo' title='Jitfin Logo' />
            </a>
          </h1>
        </Col>
      </Row>
    </div>
  )
};

export default SiteHeader;