import { ELoanProcessStep, ILoanProcessStep } from './../reducers/typings';
export const loanProcessSteps: ILoanProcessStep[] = [
  {
    type: [ELoanProcessStep.PROCESS_BEGIN],
    name: 'Enable OTP Access',
    index: 1,
    route: '/'
  },
  {
    type: [ELoanProcessStep.GENERATE_GST_OTP, ELoanProcessStep.GENERATE_GST_OTP_FAILED],
    name: 'Generate OTP',
    index: 2,
    route: '/enable-gst-otp'
  },
  {
    type: [ELoanProcessStep.VALIDATE_GST_OTP, ELoanProcessStep.VALIDATE_GST_OTP_FAILED],
    name: 'Validate OTP',
    index: 3,
    route: '/validate-gst-otp'
  },
  {
    type: [ELoanProcessStep.PROCESS_COMPLETE],
    name: 'Complete',
    index: 4,
    route: '/success'
  }
];