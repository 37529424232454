import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import sagas from '../sagas'

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleWare)));
sagaMiddleWare.run(sagas);

export default store;